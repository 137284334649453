<template>
  <div
    v-if="$can('read', 'ModuleMaster.SpecTemplate')"
    style="height: inherit"
  >
    <b-overlay
      :show="showOverlay"
      rounded="sm"
    >
      <!-- Table Container Card -->
      <b-card
        no-body
      >

        <div class="m-2">

          <!-- Table Top -->
          <b-row>

            <!-- Per Page -->
            <b-col
              cols="12"
              sm="12"
              md="6"
            >
              <label>{{ $t('Entries') }}</label>
              <v-select
                v-model="perPage"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block ml-50 mr-1"
              />
            </b-col>

            <!-- Search -->
            <b-col
              cols="12"
              sm="12"
              md="6"
            >
              <div class="d-flex align-items-center justify-content-end w-100 mt-1 mt-md-0">
                <b-button
                  v-if="$can('create', 'ModuleMaster.SpecTemplate')"
                  variant="primary"
                  class="w-50"
                  @click="addSpecTemplateForm"
                >
                  {{ `${$t('Add')} ${$t('TitleMaster.SpecTemplate')}` }}
                </b-button>
                <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block mr-1 ml-1"
                  :placeholder="`${$t('Search')}...`"
                />
              </div>
            </b-col>
          </b-row>

        </div>
        <b-table
          ref="refSpecTemplateTable"
          :items="getListSpecTemplate"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          hover
          empty-text="No matching records found"
          :sort-desc.sync="isSortDirDesc"
          class="position-relative"
          selectable
          select-mode="multi"
        >

          <!-- Column: ID -->
          <template #cell(id)="data">
            <div style="margin-top: 0.3rem;">
              <span style="font-size: 18px;">
                <b-badge
                  pill
                  variant="light-info"
                  class="px-1 py-25"
                >
                  <feather-icon
                    icon="HashIcon"
                    style="margin-top: 0.11rem;"
                  />
                  {{ data.item.id }}
                </b-badge>
              </span>
            </div>
          </template>

          <!-- Column: Name -->
          <template #cell(name)="data">
            <div style="margin-top: 0.3rem;">
              <b-list-group>
                <b-list-group-item
                  style="padding: 0.4rem 0.6rem 0.2rem 0.6rem;"
                >
                  <span style="font-size: 16px;">{{ data.item.name }}</span>
                </b-list-group-item>
              </b-list-group>
            </div>
          </template>

          <!-- Column: Value -->
          <template #cell(value)="data">
            <div style="margin-top: 0.3rem;">
              <span style="font-size: 18px;">
                <b-badge
                  pill
                  variant="light-secondary"
                  class="px-1 py-25"
                >
                  {{ data.item.value }}
                </b-badge>
              </span>
            </div>
          </template>

          <!-- Column: Unit -->
          <template #cell(unit)="data">
            <div
              v-if="data.item.unit !== ''"
              style="margin-top: 0.3rem;"
            >
              <span style="font-size: 18px;">
                <b-badge
                  pill
                  variant="light-warning"
                  class="px-1 py-25"
                >
                  {{ data.item.unit }}
                </b-badge>
              </span>
            </div>
          </template>

          <!-- Column: Type -->
          <template #cell(type)="data">
            <div
              v-if="data.item.type_show !== ''"
              style="margin-top: 0.3rem;"
            >
              <span style="font-size: 18px;">
                <b-badge
                  pill
                  variant="light-success"
                  class="px-1 py-25"
                >
                  {{ data.item.type_show }}
                </b-badge>
              </span>
            </div>
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">

            <div class="text-nowrap">
              <a
                class="action-icon"
                @click.stop="editSpecTemplateForm(data)"
              >
                <feather-icon
                  v-if="$can('write', 'ModuleMaster.SpecTemplate')"
                  :id="`spec-template-row-${data.item.id}-edit-icon`"
                  icon="EditIcon"
                  class="cursor-pointer mr-1"
                  size="16"
                  @click="editSpecTemplateForm(data)"
                />
                <b-tooltip
                  v-if="$can('write', 'ModuleMaster.SpecTemplate')"
                  :title="`${$t('Edit')} ${$t('ModuleMaster.SpecTemplate')}`"
                  :target="`spec-template-row-${data.item.id}-edit-icon`"
                  :delay="{ show: 2500, hide: 50 }"
                  placement="left"
                />
              </a>

              <a
                class="action-icon"
                @click.stop="viewSpecTemplateForm(data)"
              >
                <feather-icon
                  v-if="$can('read', 'ModuleMaster.SpecTemplate')"
                  :id="`spec-template-row-${data.item.id}-view-icon`"
                  icon="EyeIcon"
                  size="16"
                  class="cursor-pointer mr-1"
                  @click="viewSpecTemplateForm(data)"
                />
                <b-tooltip
                  v-if="$can('read', 'ModuleMaster.SpecTemplate')"
                  :title="`${$t('View')} ${$t('ModuleMaster.SpecTemplate')}`"
                  :target="`spec-template-row-${data.item.id}-view-icon`"
                  :delay="{ show: 2500, hide: 50 }"
                  placement="left"
                />
              </a>

              <a
                class="action-icon"
                @click.stop="deleteSpecTemplateForm(data)"
              >
                <feather-icon
                  v-if="$can('delete', 'ModuleMaster.SpecTemplate')"
                  :id="`spec-template-row-${data.item.id}-delete-icon`"
                  icon="TrashIcon"
                  variant="light-success"
                  size="16"
                  class="cursor-pointer mr-1"
                  @click="deleteSpecTemplateForm(data)"
                />
                <b-tooltip
                  v-if="$can('delete', 'ModuleMaster.SpecTemplate')"
                  :title="`${$t('Delete')} ${$t('ModuleMaster.SpecTemplate')}`"
                  :target="`spec-template-row-${data.item.id}-delete-icon`"
                  :delay="{ show: 2500, hide: 50 }"
                  placement="right"
                />
              </a>

            </div>
          </template>

        </b-table>
        <div class="mx-2 mb-2">
          <b-row>

            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">
                {{ $t('Showing {from} to {to} of {of} entries',
                      {from: dataMeta.from, to: dataMeta.to, of: dataMeta.of })
                }}
              </span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >

              <b-pagination
                v-model="currentPage"
                :total-rows="totalSpecTemplates"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>

            </b-col>

          </b-row>
        </div>
      </b-card>

      <spec-template-form
        v-model="shallShowSpecTemplateFormModal"
        :spec-template-data="specTemplateData"
        :type-options="typeOptions"
        :type-spec-template-form="typeSpecTemplateForm"
        @spec-template-form-update="updateSpecTemplateForm"
        @discard-spec-template-form="discardSpecTemplateForm"
      />
    </b-overlay>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput,
  BTable, BButton,
  BBadge, BPagination, BTooltip, BListGroup, BListGroupItem,
  BOverlay,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {
  ref,
  onUnmounted,
} from '@vue/composition-api'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import { $themeConfig } from '@themeConfig'
import tableSpecTemplate from './tableSpecTemplate'
import storeSpecTemplate from './storeSpecTemplate'
import SpecTemplateForm from './SpecTemplateForm.vue'

export default {
  components: {
    SpecTemplateForm,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BBadge,
    BPagination,
    BTooltip,
    BListGroup,
    BListGroupItem,
    BButton,
    BOverlay,

    vSelect,
    FeatherIcon,
  },
  data() {
    return {
      showOverlay: false,
      specTemplateData: {},
      dialog: false,
      typeOptions: [],
    }
  },
  mounted() {
    this.getDefaultSpecTemplate()
    this.locale = this.$i18n.locale
  },
  methods: {
    getDefaultSpecTemplate() {
      this.showOverlay = true
      store
        .dispatch('store-spec-template/getDefaultSpecTemplate', { language: this.$i18n.locale })
        .then(response => {
          const okMsg = this.$i18n.t('Process on successfully')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: okMsg,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
          })
          this.showOverlay = false
          this.specTemplateData = response.data
          this.typeOptions = (response.data.type_options !== undefined) ? response.data.type_options : []
        })
        .catch(error => {
          const { response, message } = error
          if (response) {
            const errorMsg = this.$i18n.t(response?.data.error)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMsg,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          } else if (message) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error?.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          }
          this.showOverlay = false
        })
    },
    addSpecTemplateForm() {
      this.showOverlay = true
      store
        .dispatch('store-spec-template/getDefaultSpecTemplate', { language: this.$i18n.locale })
        .then(response => {
          const okMsg = this.$i18n.t('Process on successfully')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: okMsg,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
          })
          this.showOverlay = false
          this.specTemplateData = response.data
          this.typeSpecTemplateForm = 'Add'
          this.shallShowSpecTemplateFormModal = true
        })
        .catch(error => {
          const { response, message } = error
          if (response) {
            const errorMsg = this.$i18n.t(response?.data.error)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMsg,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          } else if (message) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error?.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          }
          this.showOverlay = false
        })
    },
    editSpecTemplateForm(data) {
      this.showOverlay = true
      store
        .dispatch('store-spec-template/getSpecTemplate', { language: this.$i18n.locale, id: data.item.id })
        .then(response => {
          const okMsg = this.$i18n.t('Process on successfully')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: okMsg,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
          })
          this.showOverlay = false
          this.specTemplateData = response.data
          this.typeSpecTemplateForm = 'Edit'
          this.shallShowSpecTemplateFormModal = true
        })
        .catch(error => {
          const { response, message } = error
          if (response) {
            const errorMsg = this.$i18n.t(response?.data.error)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMsg,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          } else if (message) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error?.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          }
          this.showOverlay = false
        })
    },
    viewSpecTemplateForm(data) {
      this.showOverlay = true
      store
        .dispatch('store-spec-template/getSpecTemplate', { language: this.$i18n.locale, id: data.item.id })
        .then(response => {
          const okMsg = this.$i18n.t('Process on successfully')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: okMsg,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
          })
          this.showOverlay = false
          this.specTemplateData = response.data
          this.typeSpecTemplateForm = 'View'
          this.shallShowSpecTemplateFormModal = true
        })
        .catch(error => {
          const { response, message } = error
          if (response) {
            const errorMsg = this.$i18n.t(response?.data.error)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMsg,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          } else if (message) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error?.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          }
          this.showOverlay = false
        })
    },
    deleteSpecTemplateForm(data) {
      this.$swal({
        title: this.$i18n.t('Are you sure?'),
        text: this.$i18n.t("You won't be able to revert this"),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$i18n.t('Yes, delete it'),
        cancelButtonText: this.$i18n.t('Cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value === true) {
          store.dispatch('store-spec-template/deleteSpecTemplate', { id: data.item.id })
            .then(() => {
              const okMsg = this.$i18n.t('Process on successfully')
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: okMsg,
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                },
              },
              {
                position: 'bottom-right',
              })
              this.$swal({
                icon: 'success',
                title: this.$i18n.t('Deleted'),
                text: this.$i18n.t('Your data has been deleted'),
                timer: 3000,
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
              this.refetchData()
            })
            .catch(error => {
              const { response, message } = error
              if (response) {
                let errorMsg = this.$i18n.t(response?.data.error)
                if (errorMsg === '') {
                  errorMsg = this.$i18n.t(response?.data.message)
                }
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: errorMsg,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                },
                {
                  position: 'bottom-right',
                })
              } else if (message) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error?.message,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                },
                {
                  position: 'bottom-right',
                })
              }
            })
        }
      })
    },
    updateSpecTemplateForm() {
      this.refetchData()
    },
    discardSpecTemplateForm() {
      this.refetchData()
    },
  },
  setup() {
    const shallShowSpecTemplateFormModal = ref(false)
    const typeSpecTemplateForm = ref('')

    if (!store.hasModule('store-spec-template')) store.registerModule('store-spec-template', storeSpecTemplate)
    onUnmounted(() => {
      if (store.hasModule('store-spec-template')) store.unregisterModule('store-spec-template')
    })

    const {
      getListSpecTemplate,
      tableColumns,
      perPage,
      currentPage,
      totalSpecTemplates,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      locale,
      refSpecTemplateTable,
      refetchData,
    } = tableSpecTemplate()

    const filterList = JSON.parse(localStorage.getItem(`${$themeConfig.app.session}-filter-spec-template-list`))
    if (filterList !== null) {
      searchQuery.value = (filterList.searchQuery !== null && filterList.searchQuery !== undefined) ? filterList.searchQuery : ''
      perPage.value = (filterList.perPage !== null && filterList.perPage !== undefined) ? filterList.perPage : ''
      currentPage.value = (filterList.page !== null && filterList.page !== undefined) ? filterList.page : ''
      sortBy.value = (filterList.sortBy !== null && filterList.sortBy !== undefined) ? filterList.sortBy : ''
      isSortDirDesc.value = (filterList.sortDesc !== null && filterList.sortDesc !== undefined) ? filterList.sortDesc : ''
    }

    return {
      shallShowSpecTemplateFormModal,
      typeSpecTemplateForm,

      getListSpecTemplate,
      tableColumns,
      perPage,
      currentPage,
      totalSpecTemplates,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      locale,
      refSpecTemplateTable,
      refetchData,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.spec-template-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss">
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.dark-layout {
  div{
    .b-overlay {
      .bg-light {
        background-color: $theme-dark-body-bg !important;
      }
    }
    .swal2-popup {
      background-color: $theme-dark-body-bg !important;
    }
  }
  .table.b-table > tbody .b-table-row-selected.table-active td {
    background-color: $theme-dark-table-active-bg;
  }
  .table.b-table.table-hover > tbody > tr.table-active:hover td,
  .table.b-table.table-hover > tbody > tr.table-active:hover th {
    background-image: linear-gradient($theme-dark-table-active-hover-bg, $theme-dark-table-active-hover-bg);
  }
}
.b-table-selectable{
  .feather{
    font-size: 1.3rem;
  }
}
.action-icon {
  :hover {
    color: #429dc8 !important;
  }
}
</style>
